/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useContext } from "react"
import { Menu, Transition } from "@headlessui/react"
import { AuthContext } from "../context/AuthContext"
import ExternalLinkIcon from "../icons/external-link"
import classNames from "../utils/classnames"

const AuthButton = (): JSX.Element => {
  const { isAuthenticated, logout, user, loginWithAccount, loginWithConsent } =
    useContext(AuthContext)
  const initial = user?.givenName?.substring(0, 1)
  return !isAuthenticated ? (
    <button
      className="bg-blue hover:bg-green text-white p-3 ml-2"
      onClick={loginWithConsent}
    >
      Login
    </button>
  ) : (
    <Menu as="div" className="relative inline-block text-left ml-2">
      {({ open }): JSX.Element => (
        <>
          <div>
            <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-100 focus:ring-green-500">
              <span className="sr-only">Open user profile menu</span>
              {user?.photo ? (
                <img
                  className="inline-flex items-center border-2 border-blue justify-center h-12 w-12 rounded-full bg-blue-500"
                  src={user?.photo}
                  title={`Logged in as ${user?.displayName}`}
                  alt={`${user?.displayName}'s avatar`}
                />
              ) : (
                <span
                  title={`Logged in as ${user?.displayName}`}
                  className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-500"
                >
                  <span className="text-xl font-medium leading-none text-white">
                    {initial}
                  </span>
                </span>
              )}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-50 origin-top-right absolute right-0 mt-2 w-56 shadow-xl bg-blue border border-gray-900 ring-1 ring-gray-900 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <a
                      href="https://mail.georgiancollege.ca"
                      target="_blank"
                      className={classNames(
                        active ? `bg-green text-white` : `text-white`,
                        `px-4 py-2 text-sm flex content-center`
                      )}
                      rel="noreferrer"
                    >
                      Email{` `}
                      <ExternalLinkIcon className="ml-2 w-4 h-4 inline-block" />
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <a
                      href="http://mygclife.ca"
                      target="_blank"
                      className={classNames(
                        active ? `bg-green text-white` : `text-white`,
                        `px-4 py-2 text-sm flex content-center`
                      )}
                      rel="noreferrer"
                    >
                      Portal{` `}
                      <ExternalLinkIcon className="ml-2 w-4 h-4 inline-block" />
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      onClick={loginWithAccount}
                      className={classNames(
                        active ? `bg-green text-white` : `text-white`,
                        `block px-4 py-2 text-sm w-full text-left`
                      )}
                    >
                      Switch account
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      onClick={logout}
                      className={classNames(
                        active ? `bg-green text-white` : `text-white`,
                        `block px-4 py-2 text-sm w-full text-left`
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default AuthButton

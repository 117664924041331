import React, { useContext } from "react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import isBrowser from "../utils/isBrowser"

interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  to: string
  activeClassName?: string
  partiallyActive?: boolean
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: ILinkProps): JSX.Element => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const {
    showMainContent,
    setShowMainContentWithSideEffects,
    setMegaMenuActive,
    setMobileMenuOpen,
  } = useContext(UserInterfaceContext)
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        onClick={
          isBrowser && window.loadster
            ? undefined
            : (e): void => {
                e.preventDefault()
                // console.log(e)
                setMobileMenuOpen(false)
                setMegaMenuActive(``)
                setShowMainContentWithSideEffects(false)
                window.setTimeout(() => {
                  // setShowMainContent(true)
                  navigate(to)
                }, 50)
              }
        }
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export default Link

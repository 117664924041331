import React, { useContext } from "react"
import CloseThickIcon from "../icons/close-thick"
import { PtsCartContext } from "../context/PtsCartContext"
import ISection from "../interfaces/ISection"

interface IPtsCartListProps {
  sections: Array<ISection>
}

const PtsCartList = ({ sections }: IPtsCartListProps): JSX.Element => {
  const { removeFromPtsCart } = useContext(PtsCartContext)
  return (
    <div className="block shadow">
      <div className="grid grid-cols-5 bg-gray-50 border-b border-gray-100">
        <div className="col-span-3 px-4 py-2">Course</div>
        <div className="py-2 px-4">Fees</div>
        <div className="py-2 px-4"></div>
      </div>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`grid grid-cols-5 ${
            index % 2 === 0 ? `bg-white` : `bg-gray-50`
          }`}
        >
          <div className="col-span-3 px-4 py-2">
            <strong>{section.Title}</strong>
            <dl className="grid grid-cols-3 text-sm">
              <dt>Term</dt>
              <dd className="col-span-2">{section.TermCode}</dd>
              <dt>CRN</dt>
              <dd className="col-span-2">{section.CRN}</dd>
              <dt>Subject</dt>
              <dd className="col-span-2">{section.SubjCode}</dd>
            </dl>
          </div>
          <div className="py-2 px-4">${section.Fees}</div>
          <div className="py-2 px-4 flex w-full text-center items-center">
            <button
              onClick={(): void => removeFromPtsCart(section)}
              className="w-full block"
            >
              <CloseThickIcon className="text-red-500 w-4 h-4 mx-auto" />
            </button>
          </div>
        </div>
      ))}
      <div className="grid grid-cols-5 bg-gray-50 border-t border-gray-100">
        <div className="col-span-3 px-4 py-2 font-bold text-right">Total</div>
        <div className="py-2 px-4">
          $
          {sections.reduce<string>(
            (total, section): string =>
              // console.log({ total, section, float: parseFloat(section.Fees) })
              parseFloat(
                `${
                  parseFloat(total) +
                  (section.Fees !== `` ? parseFloat(section.Fees) : 0)
                }`
              ).toFixed(2),
            `0`
          )}
        </div>
      </div>
    </div>
  )
}

export default PtsCartList

import React, { Fragment, useContext } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import CloseIcon from "../icons/close"
import Link from "./link"

interface ILocalMobileMenuProps {
  children: React.ReactNode
  label?: string
  homeLink?: string
}

const LocalMobileMenu = ({
  children,
  label,
  homeLink,
}: ILocalMobileMenuProps): JSX.Element => {
  const { localMobileMenuOpen, setLocalMobileMenuOpen } =
    useContext(UserInterfaceContext)
  return (
    <Transition.Root show={localMobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-[9999] inset-0 overflow-hidden"
        open={localMobileMenuOpen}
        onClose={setLocalMobileMenuOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 left-0 sm:max-w-xs max-w-[90%] flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="-translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="-translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-blue text-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">
                        <Link
                          onClick={(): void => setLocalMobileMenuOpen(false)}
                          to={homeLink}
                        >
                          {label}
                        </Link>
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className=""
                          onClick={(): void => setLocalMobileMenuOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <CloseIcon
                            className="text-white h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LocalMobileMenu
